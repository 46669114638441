import consumer from "./consumer"



$(document).on('turbolinks:load', function () {

  console.log(">>>>>>>>>>>"+$("#xy_room_uuid").val())

  if($("#xy_room_uuid").val()){

    console.log("Creando canal");
    consumer.subscriptions.create({ channel: "ActividadXyChannel", uuid: $("#xy_room_uuid").val() }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to XY!");
      },

      disconnected() {
        console.log("Disconnected to XY!");
      },

      received(data) {
        console.log(">>> "+data["accion"]);
        let uuid = $("#xy_room_uuid").val();
        let usuario_id = $("#user_id").val();

        if(data["accion"]=="inicio_sesion"){
          console.log("A");
          
          xy_cargar_participantes(uuid);
        }else if(data["accion"]=="iniciado"){
                    console.log("B");
          
          xy_cargar_participantes(uuid);
        }else if(data["accion"]=="iniciar_ronda"){
          console.log("iniciar_ronda");
          carga_ronda(uuid,data["ronda"],data["tiempo"],data["alerta"]);
          
        }else if(data["accion"]=="resultados"){
          console.log("resultados");
          carga_resultados(uuid,usuario_id,data["ronda"]);
          
                    
          
        }else if(data["accion"]=="terminado"){
           console.log("terminado");
           termina(uuid,usuario_id);
        }else if(data["accion"]=="esperando"){
          console.log("esperando");
          esperar(uuid,data["ronda"],data["tiempo"]);
        }else if(data["accion"]=="break"){
          breaktime(uuid,data["ronda"],data["tiempo"],data["comentario"]);
        }


  
      },

      announce: function() {
        return this.perform('announce');
      },

      speak: function() {
        return this.perform('speak');
      },

      play: function() {
        return this.perform('play');
      }
    }); //end consumer
  }
})
