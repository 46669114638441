window.llegar_acuerdo = function(value){

 		if (value == 'no') {
	        $(".form-group.acuerdo_razon_no").show();
	        $(".acuerdos select").val("");
	        $("#acuerdo_razon_no").val("");
	        $(".acuerdos select").attr('disabled', true);
	        $("#acuerdo_comentarios").attr('disabled', true);
	        $("#acuerdo_comentarios").val("");


	    }
	    else if (value == 'si') {
	        $(".form-group.acuerdo_razon_no").hide();
	        $("#acuerdo_razon_no").val("");
	        $(".acuerdos select").attr('disabled', false);
	        $("#acuerdo_comentarios").attr('disabled', false);

	    }
	    calcula();
}	 

window.cargar_participantes = function(uuid){

	 if(!uuid)return;

	 console.log("Actualizando participantes "+uuid);

		$.ajax({
		  url: "/pelicula_negociacion/participantes/"+uuid,
		  context: document.body
		}).done(function(data) {
			
			let listos = 0;
			let total = 0;
			$(".participantes").empty();
			$.each(data, function(key,value) {
				$(".participantes").append("<li>"+value.nombre+" - " +value.rol_pelicula +" </li>");
			});

			
		 
		});
}




function obtenerValor(data,valor){

	//console.log(">>"+valor);
	let seleccion = parseInt($("#"+valor).val());
			

	if(seleccion && seleccion!="0" ){
		return data[seleccion-1];
	}else{
		return 0;
	}
	
}


window.valida = function(){

	var res = true;
	$( ".acuerdos select" ).each(function( index ) {
	  	if($( this ).val()==""){
	  		res = false;
	  		return false;
	  	}
	});

	return res;

}


//A	PUBLICISTA
window.calcula_director = function(){

	

	data_A01 = [0,200,400,600,800,1000,1200,1400,1600];
  data_A02 = [0,100,150,200,300,400];
	data_A03 = [300,200,100,0];	//20220329
	data_A04 = [750,300,-250];//20220329
	data_A05 = [500,300,0];//20220329
	data_A06 = [-1500,-800,-500,-50,350,750,1000,1550];//20220329
	data_A07 = [500,400,300,200,0]; 
	data_A08 = [1500,300,300,-500];//20220329
	data_A09 = [0,300,400,500];
  data_A10 = [0,100,200,500,1000,2000]
  data_A11 = [-750,-100,1000,2000]//20220329
  data_A12 = [1000,0]
  data_A13 = [-6000,-2400,-500,-100,200,2000,3000,4200]
	


    let total_director = 0;
    total_director += obtenerValor(data_A01,"acuerdo_honorarios_agencia");
    total_director += obtenerValor(data_A02,"acuerdo_presupuesto_produccion");
    total_director += obtenerValor(data_A03,"acuerdo_productor_ejecutivo");
    total_director += obtenerValor(data_A04,"acuerdo_director_cortometraje");
    total_director += obtenerValor(data_A05,"acuerdo_direccion_arte");
    total_director += obtenerValor(data_A06,"acuerdo_viaticos_direccion");
    total_director += obtenerValor(data_A07,"acuerdo_protagonista_fem");
    total_director += obtenerValor(data_A08,"acuerdo_protagonista_mas");
    total_director += obtenerValor(data_A09,"acuerdo_locaciones");
    total_director += obtenerValor(data_A10,"acuerdo_agenda_postproduccion");
    total_director += obtenerValor(data_A11,"acuerdo_control_editorial");
    total_director += obtenerValor(data_A12,"acuerdo_licenciamiento_cortometraje");
    total_director += obtenerValor(data_A13,"acuerdo_bono_agencia");

	 

	$(".total_director").html(total_director);
}


//B	DIRECTOR DE IMAGEN
window.calcula_productor = function(){

	



	data_A01 = [1600,1400,1200,1000,800,600,400,200,0];
  data_A02 = [2000,1000,500,200,100,0];
	data_A03 = [500,300,0,-500];
	data_A04 = [-100,300,1500];
	data_A05 = [500,0,-250]; //20220329
	data_A06 = [1250,850,600,200,-100,-300,-500,-1500];//20220329
	data_A07 = [300,100,100,0,-250];
	data_A08 = [-300,300,300,750];//20220329
  data_A09 = [2000,1000,-100,-500];
  data_A10 = [400,300,200,150,100,0];
  data_A11 = [500,400,300,0];
  data_A12 = [1000,0];
  data_A13 = [4500,3000,2000,350,-50,-500,-2300,-5900];



  let total_productor = 0;
    total_productor += obtenerValor(data_A01,"acuerdo_honorarios_agencia");
    total_productor += obtenerValor(data_A02,"acuerdo_presupuesto_produccion");
    total_productor += obtenerValor(data_A03,"acuerdo_productor_ejecutivo");
    total_productor += obtenerValor(data_A04,"acuerdo_director_cortometraje");
    total_productor += obtenerValor(data_A05,"acuerdo_direccion_arte");
    total_productor += obtenerValor(data_A06,"acuerdo_viaticos_direccion");
    total_productor += obtenerValor(data_A07,"acuerdo_protagonista_fem");
    total_productor += obtenerValor(data_A08,"acuerdo_protagonista_mas");
    total_productor += obtenerValor(data_A09,"acuerdo_locaciones");
    total_productor += obtenerValor(data_A10,"acuerdo_agenda_postproduccion");
    total_productor += obtenerValor(data_A11,"acuerdo_control_editorial");
    total_productor += obtenerValor(data_A12,"acuerdo_licenciamiento_cortometraje");
    total_productor += obtenerValor(data_A13,"acuerdo_bono_agencia");

	
	$(".total_productor").html(total_productor);
}

window.calcula = function(){

	console.log("calcula");
	calcula_director();
	calcula_productor();

	value = $('input[name="acuerdo[acuerdo]"]:checked').val();	

	if (value == 'no') {
		console.log("firmar");
		let id = $("#boton_firma").val();
		$("#"+id).removeClass("disabled");
		$("#"+id).attr("disabled",false);
	}else if(valida()){
		console.log("firmar");
		let id = $("#boton_firma").val();
		$("#"+id).removeClass("disabled");
		$("#"+id).attr("disabled",false);
	}else{
		console.log("NO firmar");
		let id = $("#boton_firma").val();
		$("#"+id).addClass("disabled");
		$("#"+id).attr("disabled",true);
		
	}
		
	
}


window.listo = function(){


	
	$(".workspace").show();
	
	
}


$(document).on('turbolinks:load', function () {


	let uuid = $("#room_uuid").val();
	cargar_participantes(uuid);

	$(".form-group.acuerdo_razon_no").hide();


	calcula();

	
});