

window.breaktime = function(uuid,ronda,tiempo,comentario){

	console.log(uuid+" " +ronda +" "+tiempo);

	$(".seleccion_tarjetas").hide();
	$(".comentario_break").show();

	$(".texto_comentario").html(comentario);
	$("#breaktime_timer").html("");

	let intervalID2 =  window.setInterval((function(){
	   var start = Date.now();
	   var textNode = document.createTextNode(tiempo);
	   document.getElementById('breaktime_timer').appendChild(textNode);
	   return function() {
	   		temporizador = tiempo-Math.floor((Date.now()-start)/1000);	   		
	        textNode.data = temporizador;
	        if(temporizador<=0)clearInterval(intervalID2);
	        };
	   }()), 1000);
	
}

window.esperar = function(uuid,ronda,tiempo){

	console.log(uuid+" " +ronda +" "+tiempo);

	$(".preparar_actividad").hide();
	$(".iniciando_actividad").show();


	let intervalID =  window.setInterval((function(){
	   var start = Date.now();
	   var textNode = document.createTextNode(tiempo);
	   document.getElementById('iniciando_actividad_timer').appendChild(textNode);
	   return function() {
	   		temporizador = tiempo-Math.floor((Date.now()-start)/1000);	   		
	        textNode.data = temporizador;
	        if(temporizador<=0)clearInterval(intervalID);
	        };
	   }()), 1000);
	
}


window.termina = function(uuid,usuario){
	console.log("Termina xy");
	$(".seleccion_tarjetas").hide();
	$(".terminar_actividad").show();
	cargar_resultados_finales(uuid);
	
}

window.carga_resultados = function(uuid,usuario,ronda){
	
	console.log(uuid);
	console.log(usuario);
	console.log(ronda);

	
	$.ajax({
	  type: "POST",
	  data: {user_id: usuario, ronda: ronda},
	  url: "/juego_xy/ronda_info/"+uuid,
	  context: document.body
	}).done(function(data) {

		console.log("done");
		console.log(data);
		console.log(data.ronda);

		



		if(ronda==1)$(".rondas").empty();

		let jugada = "";		

		if(data){
			jugada = data.jugada.jugada;
		}


		let premio ="";

		if(data.jugada.premio!=1){
			premio = "por "+data.jugada.premio;
		}

		$(".rondas").append("<tr><td>"+ronda+"</td>"+
	   		      '<td class="text-center">'+data.resultados.x+' X <br>'+data.resultados.y+' Y</td>'+
      			  '<td class="text-center">'+jugada.toUpperCase()+'</td>'+
      			  '<td class="text-center">'+data.jugada.resultado+'</td>'+
                  '<td class="text-center">'+premio+'</td>'+
                  '<td class="text-center">'+data.jugada.total+'</td>'+
                  '</tr>');

		$("#total").val(parseInt($("#total").val())+data.jugada.total);
		$(".resultado_tag").html($("#total").val());		

	});	


		     	
				  

		      	


}

window.realizar_jugada = function(uuid,usuario,ronda,jugada){

console.log(uuid);
console.log(usuario);
console.log(jugada);

	console.log("Empezar "+uuid);
	$.ajax({
	  type: "POST",
	  data: {user_id: usuario, ronda: ronda, jugada: jugada},
	  url: "/juego_xy/jugar/"+uuid,
	  context: document.body
	}).done(function(data) {

		console.log("done");
	});	
}

window.carga_ronda = function(uuid,ronda,tiempo,alerta){

	console.log("carga_ronda "+uuid);
	console.log("ronda "+ronda.numero);

		$(".comentario_break").hide();
		$(".tarjeta").attr("disabled", false);
		$(".card").removeClass("selected");
		$(".preparar_actividad").hide();
		$(".iniciando_actividad").hide();
		$(".seleccion_tarjetas").show();



		$("#numero_ronda").html(ronda.numero);
		$("#ronda").val(ronda.numero);
		

		$(".reloj").show();
		$(".reloj-segundos").removeClass("alerta");
		$('.reloj-segundos').html("");		        	

		let intervalID =  window.setInterval((function(){
		   var start = Date.now();
		   var textNode = document.createTextNode(tiempo);
		   document.getElementById('ejecutando_actividad_timer').appendChild(textNode);
		   return function() {

		   		temporizador = tiempo-Math.floor((Date.now()-start)/1000);	   		
		        textNode.data = temporizador;

		        	if(temporizador>0){
		        		$(".tarjeta").attr("disabled", false);
		        	}

		        	if(temporizador<=alerta){		        		
		        		$(".reloj-segundos").addClass("alerta");
		        	}
		        	if(temporizador<=0){
		        		$(".tarjeta").attr("disabled", true);
		        		
		        		clearInterval(intervalID);
		        	}
		        };
		   }()), 1000);




}

window.empezar_actividad = function(uuid,usuario){
	
	console.log("Empezar "+uuid);
	$.ajax({
	  type: "POST",
	  async: false,
	  data: {user_id: usuario},
	  url: "/juego_xy/empezar/"+uuid,
	  context: document.body})
		.done(function() {
		 console.log("done");
		})
		.fail(function() {
				$("#boton_empezar").html("Existió un error o ya no te encuentras en el cuarto.");
				$("#boton_empezar").removeClass("btn-success");
				$("#boton_empezar").addClass("btn-danger");
				$("#boton_empezar").attr("disabled", true);

				$(".seleccion_tarjetas").remove();
		});

      /*  error: function(){
        	console,lg("Error");
            	
        },
        done: function(msg){ 
            
                res = true;
            
        }
	});	
*/
	
}



window.xy_cargar_participantes = function(uuid){

	 console.log("Actualizando participantes "+uuid);

	$.ajax({
	  url: "/juego_xy/participantes/"+uuid,
	  context: document.body
	}).done(function(data) {
		
		let listos = 0;
		let total = 0;
		$(".participantes").empty();
		$.each(data, function(key,value) {
			$(".participantes").append("<li>"+value.usuario+ " ("+value.estado+")</li>");
			total+=1;

			if(value.estado=="listo")listos++;
		});

		if(total==4){
			console.log("listos");
			$(".instruccion_00").html("Para iniciar la dinámica todos los usuarios deben dar clic en empezar.")
		}

		
	 
	});
}

window.cargar_resultados_finales = function(uuid){

	 console.log("Resultados finales "+uuid);

	$.ajax({
	  url: "/juego_xy/participantes/"+uuid,
	  context: document.body
	}).done(function(data) {
		
		
		let i = 1;
		$(".resultados_finales").empty();
		$.each(data, function(key,value) {
			//$(".participantes").append("<li>"+value.usuario+ " ("+value.estado+")</li>");
			//total+=1;
			//
			//if(value.estado=="listo")listos++;

			$(".resultados_finales").append("<tr><td>"+(i++)+"</td>"+
				
				"<td>"+value.usuario+"</td>"+
				"<td>"+value.total+"</td>"+
                  '</tr>');			
		});

		

		
	 
	});
}


$(document).on('turbolinks:load', function () {


	let uuid = $("#xy_room_uuid").val();

	if(uuid){
		xy_cargar_participantes(uuid);	

	



	$( "#boton_empezar" ).click(function() {
		let uuid = $("#xy_room_uuid").val();
		let user_id = $("#user_id").val();

	

			


				$(this).html("Esperando a que todos se registren");
				$(this).removeClass("btn-success");
				$(this).addClass("btn-secondary");
				$(this).attr("disabled", true);

				empezar_actividad(uuid,user_id);
			
		});

		$( ".tarjeta" ).click(function() {
			let jugada = $(this).data("carta");
			console.log("tarjeta click");
			let uuid = $("#xy_room_uuid").val();
			let user_id = $("#user_id").val();		
			let ronda = $("#ronda").val();	
			realizar_jugada(uuid,user_id,ronda,jugada)

			$(".card").removeClass("selected");
			$(".card-"+jugada).addClass("selected");


			
		});

		}

});