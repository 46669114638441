import consumer from "./consumer"


$(document).on('turbolinks:load', function () {

  const acuerdoChannel = consumer.subscriptions.create({ channel: "AcuerdoChannel", uuid: $("#room_uuid").val() }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("Connected AcuerdoChannel");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
       console.log("received AcuerdoChannel");
       if(data.message=="iniciar_acuerdos"){
        listo();
        $("#boton_listo").html("Realizar acuerdos");        
       }else if(data.message=="finalizado"){ 
          console.log("FINALIZADO");
          $(".finalizado").show();
          $("#boton_listo").html("Finalizado");      
            $(".puntuaje-a").show();
            $(".puntuaje-b").show();  
       }else if(data.message=="firmado"){ 
          console.log("FIRMADO");
          console.log(data);
          $("select").attr("disabled", true);
          $("textarea").attr("readonly", true);
          $(':radio:not(:checked)').attr('disabled', true);

          $("."+data.boton).attr("disabled",true);
          $("."+data.boton).html("Firmado");


       }else if(data.message=="cargar_participantes"){
        let uuid = $("#room_uuid").val();
        cargar_participantes(uuid);


       }else if(data.message=="actualizar"){
          $("#acuerdo_"+data.campo).val(data.valor);
           calcula();
       }else if(data.message=="acuerdo"){       
          console.log(data);   

          if(data.valor=="si")  $("#acuerdo_acuerdo_si").prop("checked", true);
          else if(data.valor=="no") $("#acuerdo_acuerdo_no").prop("checked", true);

            
          llegar_acuerdo(data.valor);
       }else if(data.message=="acuerdo_razon_no"){       
          if(data.owner!=$("#user_correo").val()){
            $("#acuerdo_razon_no").val(data.valor);
          }
       }else if(data.message=="acuerdo_comentarios"){       
          if(data.owner!=$("#user_correo").val()){
            $("#acuerdo_comentarios").val(data.valor);
          }
          
       }else{
        console.log(data);
       }
    },

    alistar: function() {
      let room = $("#room_uuid").val();
      let user = $("#user_correo").val();
      console.log("alistar");
      return this.perform('alistar',{room,user});
    },

    firmar: function() {
      let room = $("#room_uuid").val();
      let user = $("#user_correo").val();      
      return this.perform('firmar',{room,user});
    },

    knock: function(){
      console.log("knock");
      return this.perform('knock');
    },

    actualizar: function(data) {
      console.log("actualizar");
      console.log(data);
      calcula();
      data.total_productor = $(".total_productor").html();
      data.total_director = $(".total_director").html();
          
      return this.perform('actualizar',data);
    }
  });

    

    $( "#boton_listo" ).click(function(e) {
        e.preventDefault();
        console.log("listo");
        $("#boton_listo").addClass("disabled");
        $("#boton_listo").html("Esperando...");        
        acuerdoChannel.alistar();        

    });

    $( ".boton_firma" ).click(function(e) {
        e.preventDefault();
        console.log("firmar");
        //$(this).addClass("disabled");
        //$(this).html("Esperando...");        
        acuerdoChannel.firmar();        

    });


    
    $( "#acuerdo_razon_no" ).bind('input propertychange',function() {  
        let id="acuerdo_razon_no";
        let valor = ($(this).val());
        let owner = $("#user_correo").val();
        
        acuerdoChannel.actualizar({id,valor,owner});
    });
     $( "#acuerdo_comentarios" ).bind('input propertychange',function() {  
        let id="acuerdo_comentarios";
        let valor = ($(this).val());
        let owner = $("#user_correo").val();
        
        acuerdoChannel.actualizar({id,valor,owner});
    });    

    $( ".acuerdos select" ).change(function() {
      console.log( "Handler for .change() called." );
      let id = ($(this).attr('id')).substring(8);
      let valor = ($(this).val());
      acuerdoChannel.actualizar({id,valor});
     
    });
    $('input[type=radio][name="acuerdo[acuerdo]"]').change(function() {
      let id="acuerdo";
      let valor = ($(this).val());
      acuerdoChannel.actualizar({id,valor});
    });


    window.knock = function(){
      acuerdoChannel.knock();     
    }

     
    

})
